import { RichTextField } from "@prismicio/types";
import { UrlObject } from "url";
import { TFunction } from "i18next";
import { StaticImageData } from "next/image";
import { ValueOf } from "@prismicio/client/dist/types";
import { TFunctionTrans } from "./i18nextType";

export interface BasicPageProps {
  translation: TFunctionTrans &
    TFunction<"translation", undefined, "translation">;
}
interface IBilanAnswer {
  answer: string;
  score: string;
}
export interface IBilanQuestion {
  label: string;
  answers: IBilanAnswer[];
}
export interface IBilanResponse {
  scoreThreshold: number;
  text: RichTextField;
}
export interface ITab {
  title: string;
  link: string | UrlObject;
  active: boolean;
}
export interface IClassicTab {
  icon?: StaticImageData | null;
  size?: number;
  label: string;
}
export interface IConfigTab {
  code: string | undefined;
  label: string;
  icon?: StaticImageData | null;
  size?: number;
  position: number;
}
export enum EMediaType {
  Video = "Video",
  Image = "Image",
}
export type TMediaType = ValueOf<typeof EMediaType>;

export type TFrontSessionResponse = {
  app: string;
  regionCode: string;
  region: string;
};

export type TIpResponse = {
  ip: string;
};
