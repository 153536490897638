import {
  EPaymentMethods,
  ILocalStorageCart,
  IOrder,
  ISchedule,
} from "@middleware/types";
import {
  CART_TOKEN_STORAGE,
  CODE_BUNDLE_MAIN_TAXON,
  CODE_LOYALTY,
  PREFEX_CUSTOM_BUNDLE,
} from "@middleware/constants";
import Api from "@middleware/api";
import { client as env } from "@config/env/client";
import { generateDate, getNumberFromString, getPreciseSlot } from "./utils";
import { getFromStorage } from "./sessions";

export const getShippingMethodValues = (
  shippingMethods: ISchedule,
  currentDay: string
) => {
  if (!(currentDay in shippingMethods)) return [];

  return shippingMethods[currentDay].shipping_methods
    .sort((a, b) => (a.position > b.position ? 1 : -1))
    .map((method) => ({
      label: method.description,
      value: method.code,
      type: method.type,
    }));
};

export const getNextOrderToken = (currentOrder: IOrder, orders?: IOrder[]) => {
  if (!orders) return currentOrder.tokenValue;

  const currentDate = generateDate(currentOrder.chosenDeliveryDate);

  const nextOrder = orders.find(
    (order) => generateDate(order.chosenDeliveryDate) > currentDate
  );

  return nextOrder ? nextOrder.tokenValue : currentOrder.tokenValue;
};

export const getFormattedAddress = (order: IOrder) => {
  const shippingAddress = order.shippingAddress;
  const shipmentAddressLine1 = shippingAddress
    ? shippingAddress.street +
      (shippingAddress.complement !== undefined
        ? ", " + shippingAddress.complement
        : "")
    : "";

  const shipmentAddressLine2 = shippingAddress
    ? shippingAddress.postcode + ", " + shippingAddress.city
    : "";

  return { shipmentAddressLine1, shipmentAddressLine2 };
};

export const getShippingSlotsValues = (
  shippingMethods: ISchedule,
  currentDay: string
) => {
  if (!(currentDay in shippingMethods)) return [];

  return shippingMethods[currentDay].delivery_slots?.map((slot) => ({
    label: getPreciseSlot(slot.date),
    value: slot,
  }));
};

export const getShipmentTrackingUrl = (shipmentTracking: string) => {
  return (
    env.NEXT_PUBLIC_CHRONOPOST_TRACKING + "?listeNumerosLT=" + shipmentTracking
  );
};

export const getOrderLoyalty = (order: IOrder) => {
  return (
    order.adjustments.find(
      (adjustment) => adjustment.originCode === CODE_LOYALTY
    ) ?? null
  );
};

export const getTotalWithoutDiscount = (order: IOrder) => {
  const discount = order.orderPromotionTotal;

  return order.total - discount;
};

export const getCodeBundleOfOrder = (order: IOrder) => {
  return order.items.find(
    (item) => item.productTypeTaxon === CODE_BUNDLE_MAIN_TAXON
  )?.productCode as string;
};

export const getPriceBundleOfOrder = (order: IOrder) => {
  return (
    order.items.find((item) => item.productTypeTaxon === CODE_BUNDLE_MAIN_TAXON)
      ?.unitPrice ?? 0
  );
};

export const getFormulasMealsNumber = (order: IOrder) => {
  const codeBundle = getCodeBundleOfOrder(order);

  return getNumberFromString(codeBundle);
};

export const updateCartFromStorage = async (
  cart: IOrder | undefined,
  setCart: (order: IOrder) => void
) => {
  const cartToken = getFromStorage(CART_TOKEN_STORAGE) as
    | ILocalStorageCart
    | undefined;
  if (cart && cart.tokenValue !== cartToken?.token) {
    const currentOrder = await Api.cart.getCartByToken(
      cartToken?.token as string
    );
    setCart(currentOrder);
  }
};

export const isMegratedPaybox = (order: IOrder) => {
  if (order.subscription) return order.subscription.isMigratedPayboxData;

  return false;
};
export const getRemainingDeferral = (order: IOrder) => {
  if (order.subscription) return order.subscription.remainingDeferral;

  return 0;
};
export const getEnabledPaymentMethods = () => {
  return [
    EPaymentMethods.CREDIT_CARD,
    EPaymentMethods.SEPA,
    EPaymentMethods.PAYBOX,
  ];
};
export const hasCustomBundle = (order: IOrder) => {
  const customBundle = order.items.find((item) =>
    item.productCode.includes(PREFEX_CUSTOM_BUNDLE)
  );

  return customBundle !== undefined;
};
