import React from "react";
import { useCustomerSegment } from "@middleware/hooks/useCustomerSegment";
import { PrismicNextImage } from "@prismicio/next";
import style from "./BannerPromotion.module.scss";

/**
 * @typedef {import("@prismicio/client").Content.BannerPromoSlice} BannerPromoSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<BannerPromoSlice>} BannerPromoProps
 * @param { BannerPromoProps }
 */
const BannerPromo = ({ slice }) => {
  const { customerSegment } = useCustomerSegment();
  const isEnabled = slice.primary.enable;
  const promo = slice.items.find((item) => item.segment === customerSegment);

  // eslint-disable-next-line
  return isEnabled === true && promo ? (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      className={style.banner}
    >
      <PrismicNextImage field={promo.feature} />
    </section>
  ) : (
    <></>
  );
};

export default BannerPromo;
