import { EShipmentMethod, LOAD_CART } from "@middleware/constants";
import { IOrder } from "@middleware/types/commerce/salesType";
import { CHANNEL_CODES } from "./catalogType";

export interface IPromo {
  code: string;
}
export interface IBillingAddress {
  firstName: string;
  lastName: string;
  street: string;
  countryCode: string;
  city: string;
  postcode: string;
  company: string;
  phoneNumber: string;
  mobilePhone: string;
  provinceCode: string;
  complement?: string;
}
export interface IShippingAddress {
  firstName: string;
  lastName: string;
  countryCode: string;
  street: string;
  city: string;
  postcode: string;
  company: string;
  phoneNumber: string;
  mobilePhone: string;
  provinceCode: string;
  complement?: string;
}
export interface IOrderAddress {
  shippingAddress: IShippingAddress;
  billingAddress: IBillingAddress;
  useDifferentBillingAddress?: boolean;
  updateUpcomingOrders?: boolean;
}
export interface ICartInfo {
  cart: IOrder | undefined;
}
export interface ICartState extends ICartInfo {
  loadCart: () => Promise<IOrder | null>;
  getNewCart: (apply?: boolean) => Promise<IOrder | null>;
  setCart: (cart: IOrder) => void;
}
export interface ILocalStorageCart {
  token: string;
}
export interface ActionCartType extends ICartInfo {
  type: typeof LOAD_CART;
}
export interface TshippingMethodConfiguration {
  amount: number;
}
export interface IShippingMethod {
  id: number;
  code: string;
  position: number;
  type: EShipmentMethod;
  name: string;
  description: string;
  configuration: Record<CHANNEL_CODES, TshippingMethodConfiguration>;
}
export interface IShipmentDetail {
  tariffLevel: string;
  transactionID?: string;
  rank: number;
  deliverySlotCode: string;
}
export interface IDeliverySlot {
  date: string;
  details: IShipmentDetail;
}
export interface IShippingMethods {
  shipping_methods: IShippingMethod[];
  delivery_slots?: IDeliverySlot[];
}
export interface ISchedule {
  [key: string]: IShippingMethods;
}

export interface IShippingMethodsResponse {
  schedule: ISchedule;
  transactionID: string;
}

export interface ICountry {
  code: string;
  name: string;
}
export interface IChannelResponse {
  code: string;
  name: number;
  countries: ICountry[];
}
export interface IPaymentMethod {
  id: number;
  code: EPaymentMethods;
  position: number;
  name: string;
  description: string;
}
export enum EPaymentMethods {
  CREDIT_CARD = "CB",
  SEPA = "SDD",
  PAYBOX = "CB_PAYBOX",
}

export enum EPayZenFormAction {
  PAYMENT = "payment",
  UPDATE = "update",
}
