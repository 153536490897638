import dynamic from "next/dynamic";
import React, { FC } from "react";
import { SliderForm } from "@components/ui";
import { EFieldTypes, FieldProps } from "@middleware/types";

const SelectForm = dynamic(
  () =>
    import("@components/ui/Forms/Select/SelectForm").then(
      (mod) => mod.SelectForm
    ),
  {
    ssr: false,
  }
);
const CheckboxForm = dynamic(
  () =>
    import("@components/ui/Forms/Checkbox/Checkbox").then(
      (mod) => mod.CheckboxForm
    ),
  {
    ssr: false,
  }
);
const Input = dynamic(
  () => import("@components/ui/Forms/Input/Input").then((mod) => mod.Input),
  {
    ssr: false,
  }
);

const RadioButtons = dynamic(
  () =>
    import("@components/ui/Forms/RadioButtons/RadioButtons").then(
      (mod) => mod.RadioButtons
    ),
  {
    ssr: false,
  }
);

const TelInput = dynamic(
  () =>
    import("@components/ui/Forms/TelInput/TelInput").then(
      (mod) => mod.TelInput
    ),
  {
    ssr: false,
  }
);

const PlacesAutocomplete = dynamic(
  () =>
    import("@components/ui/Forms/PlacesAutocomplete/PlacesAutocomplete").then(
      (mod) => mod.PlacesAutocomplete
    ),
  {
    ssr: false,
  }
);

const Field: FC<FieldProps> = ({ variant, props }) => {
  const {
    register,
    control,
    name,
    type,
    error,
    label,
    required = false,
    placeholder,
    wrapperClass,
    values,
    className,
    marks,
    min,
    defaultValue,
    startLabel,
    endLabel,
    readonly,
    setValue,
    fieldsToComplete,
    countryCode,
    availableCountries,
    dataTest,
    disabled,
  } = props;

  return (
    <>
      {(variant === EFieldTypes.TEXT ||
        variant === EFieldTypes.EMAIL ||
        variant === EFieldTypes.NUMBER ||
        variant === EFieldTypes.PASSWORD) && (
        <Input
          name={name}
          type={type}
          label={label}
          register={register}
          error={error}
          wrapperClass={wrapperClass}
          placeholder={placeholder}
          className={className}
          readOnly={readonly}
          required={required}
        />
      )}
      {variant === EFieldTypes.SELECT && (
        <SelectForm
          name={name}
          label={label}
          control={control}
          error={error}
          wrapperClass={wrapperClass}
          placeholder={placeholder}
          values={values}
          className={className}
          required={required}
          disabled={disabled}
        />
      )}
      {variant === EFieldTypes.CHECKBOX && (
        <CheckboxForm
          name={name}
          control={control}
          label={label}
          error={error}
          wrapperClass={wrapperClass}
          className={className}
          required={required}
          dataTest={dataTest}
        />
      )}
      {variant === EFieldTypes.RADIO && (
        <RadioButtons
          name={name}
          control={control}
          label={label}
          error={error}
          wrapperClass={wrapperClass}
          className={className}
          values={values}
          defaultValue={defaultValue}
          required={required}
        />
      )}
      {variant === EFieldTypes.SLIDER && (
        <SliderForm
          name={name}
          control={control}
          label={label}
          error={error}
          wrapperClass={wrapperClass}
          className={className}
          marks={marks}
          min={min}
          defaultValue={defaultValue}
          startLabel={startLabel}
          endLabel={endLabel}
        />
      )}
      {variant === EFieldTypes.AUTOCOMPLETE_ADDRESS && (
        <PlacesAutocomplete
          name={name}
          type={type}
          label={label}
          error={error}
          wrapperClass={wrapperClass}
          placeholder={placeholder}
          className={className}
          readOnly={readonly}
          setValue={setValue}
          fieldsToComplete={fieldsToComplete}
          required={required}
          control={control}
          selectedCountry={countryCode}
        />
      )}
      {variant === EFieldTypes.TEL && (
        <TelInput
          name={name}
          label={label}
          error={error}
          wrapperClass={wrapperClass}
          className={className}
          countryCode={countryCode}
          availableCountries={availableCountries}
          required={required}
          control={control}
          placeholder={placeholder}
        />
      )}
    </>
  );
};

export default Field;
