import {
  URL_PAGE_CART,
  URL_PAGE_CUSTOMER_ORDER,
  URL_PAGE_CUSTOMER_RESTART_SUBSCRIPTION,
  URL_PAGE_FORMULES,
  URL_PAGE_FORMULES_PLUS,
} from "@middleware/constants";
import { isCustomerPaused, isCustomerSubscribed } from "@middleware/helpers";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { EOrderType } from "@middleware/types";
import { useCustomer } from "./useCustomer";
import { useCart } from "./useCart";

export const useRedirectIfSubscribed = () => {
  const { pathname } = useRouter();
  const { cart } = useCart();
  const router = useRouter();
  const { customer } = useCustomer();
  const isRestartCart = cart ? cart.type === EOrderType.RESUME : false;

  useEffect(() => {
    const pagesArray = [
      URL_PAGE_CART,
      URL_PAGE_FORMULES,
      URL_PAGE_FORMULES_PLUS,
    ];

    const checkUserSubscription = async () => {
      if (!customer || !cart || !pagesArray.includes(pathname)) return;

      if (isCustomerPaused(customer)) {
        if (!isRestartCart) {
          await router.push(
            {
              pathname: URL_PAGE_CUSTOMER_RESTART_SUBSCRIPTION,
              query: {
                alreadySubscribed: "true",
              },
            },
            URL_PAGE_CUSTOMER_RESTART_SUBSCRIPTION
          );
        }
      } else if (isCustomerSubscribed(customer)) {
        if (pagesArray.includes(pathname)) {
          await router.push(
            {
              pathname: URL_PAGE_CUSTOMER_ORDER,
              query: {
                alreadySubscribed: "true",
              },
            },
            URL_PAGE_CUSTOMER_ORDER
          );
        }
      }
    };
    void checkUserSubscription();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer, pathname, router, cart]);
};
