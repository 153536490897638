import Api from "@middleware/api";
import { SHIPPING_STEP_SECTION } from "@middleware/constants";
import {
  EPaymentMethods,
  EPaymentState,
  IOrder,
  IShippingMethodsResponse,
} from "@middleware/types";
import { useEffect, useState } from "react";

export const useShippingMethods = (cart?: IOrder, step?: number) => {
  const [methods, setMethods] = useState<IShippingMethodsResponse | false>();

  useEffect(() => {
    const getMethods = async () => {
      if (!cart || (step !== undefined && step !== SHIPPING_STEP_SECTION))
        return;

      const isPayedOrder = cart.paymentState === EPaymentState.PAID;
      const isPaymentSepa =
        cart.payments.length > 0
          ? cart.payments[0].method.code === EPaymentMethods.SEPA
          : false;
      const isSepaOrder = !isPayedOrder && isPaymentSepa;

      const shippingMethodsResponse = await Api.cart.getShippingMethods(
        cart.tokenValue,
        isSepaOrder
      );
      setMethods(shippingMethodsResponse);
    };
    void getMethods();
  }, [cart, step]);

  return { methods, setMethods };
};
