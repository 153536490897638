import { useState } from "react";
import {
  generateDate,
  getBasicFormattedDate,
  getFormattedDate,
  getShippingMethodValues,
} from "@middleware/helpers";
import { IOrder, ISchedule } from "@middleware/types";

export const useShippingMethodValues = (
  shippingMethods: ISchedule | undefined,
  order?: IOrder,
  isPaidOrder?: boolean,
  shipmentDeadlinePassed?: boolean
) => {
  const daysValues = Object.keys(shippingMethods ?? {})
    .filter((day) => {
      return (
        isPaidOrder !== true ||
        (shipmentDeadlinePassed !== true &&
          shippingMethods?.[day].shipping_methods.some(
            (method) => method.type === order?.shipments[0].method.type
          ))
      );
    })
    .map((day) => ({
      label: getFormattedDate(day),
      value: day,
      type: "",
    }));
  const firstAvailableDay = daysValues.length > 0 ? daysValues[0].value : "";
  const defaultDay = order
    ? getBasicFormattedDate(generateDate(order.chosenDeliveryDate))
    : firstAvailableDay;
  const methodsInitialValues = shippingMethods
    ? getShippingMethodValues(shippingMethods, defaultDay)
    : [];
  const [methodsValues, setMethodsValues] = useState(methodsInitialValues);

  return { daysValues, methodsValues, setMethodsValues };
};
