import Api from "@middleware/api";
import { client as env } from "@config/env/client";
import {
  EOrderType,
  ESubscriptionState,
  ICustomer,
  IOrder,
} from "@middleware/types";

export const updateCustomerFoodPreferences = async (
  currentCustomer: ICustomer | undefined,
  customerFilters: Set<string>
) => {
  if (currentCustomer === undefined) return;

  const excludedFoodPreferences = Array.from(customerFilters);
  const newCustomer = {
    ...currentCustomer,
    excludedFoodPreferences,
  };
  await Api.customer.updateCustomer(newCustomer);
};
export const getIsProspect = (customer: ICustomer | undefined): boolean => {
  return customer === undefined || !customer.currentSubscription;
};
export const isCustomerSubscribed = (
  customer: ICustomer | undefined
): boolean => {
  if (customer === undefined) return false;

  if (!customer.currentSubscription) return false;

  return [
    ESubscriptionState.ON_GOING,
    ESubscriptionState.PAUSED,
    ESubscriptionState.UNPAID,
  ].includes(customer.currentSubscription.state);
};

export const getReferralUrl = (sponsorshipCoupon: string) => {
  const storeUrl = env.NEXT_PUBLIC_STORE_URL;

  return (
    storeUrl +
    `?code=${sponsorshipCoupon}&utm_source=parrainage&utm_medium=link&utm_campaign=site`
  );
};

export const getRemainingReferrals = (
  customer: ICustomer | undefined
): number => {
  if (customer === undefined) return 0;

  const remainingReferrals =
    (customer.user.maxAllowedSponsorships ?? 0) -
    (customer.user.sponsoredUsersCount ?? 0);

  if (remainingReferrals < 0) return 0;

  return remainingReferrals;
};

export const getCustomerName = (customer: ICustomer) => {
  return customer.firstName.substring(0, 10);
};

export const isCustomerPaused = (customer: ICustomer | undefined): boolean => {
  if (customer === undefined) return false;

  if (!customer.currentSubscription) return false;

  return ESubscriptionState.PAUSED === customer.currentSubscription.state;
};

export const isOrderReact = (order?: IOrder) => {
  return order?.type === EOrderType.RESUME;
};
