import { EShipmentMethod } from "@middleware/constants";
import {
  EPaymentMethods,
  IBillingAddress,
  IPaymentMethod,
  IPromo,
  IShippingAddress,
} from "@middleware/types/commerce/cartType";

export interface IOrderItem {
  id: number;
  code: string;
  quantity: number;
  variantCode: string;
  productCode: string;
  productMainTaxon: string;
  variant: string;
  productName: string;
  unitPrice: number;
  originalUnitPrice: number;
  total: number;
  discountedUnitPrice: number;
  subtotal: number;
  productTypeTaxon: string;
  productPackaging?: number;
}

export interface IAdjustment {
  id: number;
  type: string;
  label: string;
  amount: number;
  originCode: string;
}

export enum EStateOrder {
  ORDER_STATE_CART = "cart",
  ORDER_STATE_FULFILLED = "fulfilled",
  ORDER_STATE_NEW = "new",
  ORDER_STATE_SKIPPED = "skipped",
  ORDER_STATE_CANCELED = "cancelled",
  ORDER_STATE_CLOSED = "closed",
}
export interface IPayment {
  collectionDate?: string;
  amount: number;
  method: IPaymentMethod;
  details: IPaymentDetails;
}

export interface IPaymentDetails {
  cardNumber?: string;
}
export interface IShipmentMethod {
  id: number;
  code: string;
  name: string;
  description: string;
  type: EShipmentMethod;
}
export interface IShipment {
  passageInFlowDate: string;
  shippingDate: string;
  id: number;
  method: IShipmentMethod;
  tracking?: string;
}
export type OrderItemSelection = Pick<IOrderItem, "quantity" | "code">;
export interface orderPromotionDetails {
  [key: string]: {
    amount: number;
    label: string;
  };
}
export enum EOrderType {
  ALC = "retail",
  SUBSCRIPTION = "subscription",
  RESUME = "subscription_resume",
}
export interface IOrder {
  id: number;
  type: EOrderType;
  tokenValue: string;
  subscription?: {
    isMigratedPayboxData: boolean;
    remainingDeferral: number;
  };
  subscriptionType?: {
    engagementPeriodNumber: number;
    possibleDeferral: number;
  };
  items: IOrderItem[];
  offeredItems: IOrderItem[];
  displayedOfferedItems: IOrderItem[];
  number: string;
  itemsTotal: number;
  state: EStateOrder;
  chosenDeliveryDate: string;
  modificationDeadline: string;
  payments: IPayment[];
  shipments: IShipment[];
  adjustments: IAdjustment[];
  promotionCoupon?: IPromo;
  total: number;
  taxTotal: number;
  shippingTotal: number;
  paymentState: EPaymentState;
  shippingState: EShipmentState;
  shippingAddress?: IShippingAddress;
  billingAddress?: IBillingAddress;
  orderPromotionTotal: number;
  expectedPaymentDate: string;
  orderPromotionDetails?: orderPromotionDetails | [];
}
type TRegularisationOrder = Omit<IOrder, "chosenDeliveryDate">;
export interface IRegularisationOrder extends TRegularisationOrder {
  chosenDeliveryDate: undefined;
}
export enum EPaymentState {
  PAID = "paid",
  AWAITING = "awaiting_payment",
  FIRST_ISSUE = "first_issue",
}
export enum EShipmentState {
  READY = "ready",
  SHIPPED = "shipped",
}
export enum ECreditTransferReason {
  REASON_SPONSORSHIP = "sponsorship",
  REASON_COMMERCIAL = "commercial_gesture",
  REASON_REFUND = "refund",
  REASON_ORDER = "order_payment",
  REASON_ADJUSTMENT = "adjustment",
  REASON_OTHER = "other",
}

export interface IFormToken {
  formToken: string | null;
}

export interface IMethodValue {
  label: string;
  value: string;
  type: string;
}
export interface ISddSignatureFormData {
  vads_action_mode: string;
  vads_ctx_mode: string;
  vads_page_action: string;
  vads_payment_cards: string;
  vads_site_id: string;
  vads_trans_date: string;
  vads_version: string;
}

export interface ISddFormData extends ISddSignatureFormData {
  vads_amount: number;
  vads_currency: string;
  vads_cust_email: string;
  vads_order_id: string;
  vads_payment_config: string;
  vads_redirect_success_timeout: string;
  vads_trans_id: string;
  vads_url_success: string;
  signature: string;
}
export interface IPaymentMethodValue {
  label: string;
  method: EPaymentMethods;
}
export interface IPayboxForm {
  url: string;
  data: IPayboxFormData;
}
export interface IPayboxFormData {
  PBX_SITE: string;
  PBX_RANG: string;
  PBX_IDENTIFIANT: string;
  PBX_TOTAL: string;
  PBX_DEVISE: string;
  PBX_CMD: number;
  PBX_PORTEUR: string;
  PBX_RETOUR: string;
  PBX_HASH: string;
  PBX_AUTOSEULE: string;
  PBX_LANGUE: string;
  PBX_RUF1: string;
  PBX_TIME: string;
  PBX_TYPEPAIEMENT: string;
  PBX_TYPECARTE: string;
  PBX_EFFECTUE: string;
  PBX_REFUSE: string;
  PBX_ANNULE: string;
  PBX_ATTENTE: string;
  PBX_REPONDRE_A: string;
  PBX_HMAC: string;
}
