import cn from "clsx";
import dynamic from "next/dynamic";
import React, { FC } from "react";
import { Controller } from "react-hook-form";
import { SelectProps, SelectValue } from "@middleware/types";
import { components, DropdownIndicatorProps } from "react-select";
import Image from "next/image";
import caretDown from "@static/images/icons/caret-down.svg";
import { customStyles } from "./SelectStyle";

const Select = dynamic(
  () => import("react-select").then((mod) => mod.default),
  {
    ssr: false,
    loading: () => null,
  }
);

export const SelectForm: FC<SelectProps> = ({
  name,
  values,
  label,
  control,
  placeholder,
  disabled,
  required,
  error,
  wrapperClass,
}) => {
  const DropdownIndicator: FC<DropdownIndicatorProps> = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
        <Image src={caretDown} alt="" />
      </components.DropdownIndicator>
    );
  };

  return (
    <div className={wrapperClass}>
      {label !== "" && (
        <label htmlFor={name} className={cn({ "required-field": required })}>
          {label}
        </label>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange, onBlur } }) => {
          const selectedValue = values?.find(
            (option) => JSON.stringify(value) === JSON.stringify(option.value)
          );

          return (
            <Select
              styles={customStyles}
              options={values}
              placeholder={placeholder}
              // @ts-expect-error no error expected
              onChange={(newValue: SelectValue) => onChange(newValue.value)}
              onBlur={onBlur}
              value={selectedValue}
              defaultValue={selectedValue}
              name={name}
              components={{ DropdownIndicator }}
              isDisabled={disabled}
            />
          );
        }}
      />
      {error !== undefined && (
        <span role="alert" className="erreur-field atp">
          {error}
        </span>
      )}
    </div>
  );
};
