import { getPublicParams } from "@middleware/helpers";
import { useEffect } from "react";
import {
  getFrontSessionFromApi,
  saveSessionParams,
  setToStorage,
} from "@middleware/helpers/global/sessions";
import { API_IP, CLIENT_IP } from "@middleware/constants";
import axios, { AxiosResponse } from "axios";
import { TIpResponse } from "@middleware/types";

export const useSessions = () => {
  useEffect(() => {
    const getSessions = async () => {
      const params = getPublicParams();

      await getFrontSessionFromApi();
      saveSessionParams(params);
    };

    const getMyIp = async () => {
      try {
        const res: AxiosResponse<TIpResponse> = await axios.get<TIpResponse>(
          API_IP
        );
        if (typeof res.data.ip === "string") {
          setToStorage(CLIENT_IP, res.data.ip);
        }
      } catch (error) {
        setToStorage(CLIENT_IP, "0.0.0.0");
      }

      void getSessions();
    };

    void getMyIp();
  }, []);
};
