import { IpathParams } from "@middleware/types";

export const replaceWildcardsUrl = (url: string, pathParams: IpathParams) => {
  if (pathParams !== undefined && pathParams !== null) {
    return url.replace(
      /{(.+?)}/g,
      (_, g1) => pathParams[g1 as keyof typeof pathParams] as string
    );
  }

  return url;
};

export const createUrl = (url: string, pathParams: IpathParams) => {
  url = replaceWildcardsUrl(url, pathParams);

  return url;
};

export const getPublicParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const utm_source = urlParams.get("utm_source");
  const utm_medium = urlParams.get("utm_medium");
  const utm_campaign = urlParams.get("utm_campaign");
  const codePromo = urlParams.get("code");
  const forcingFront = urlParams.get("front");
  const startDate = urlParams.get("startDate");
  const forcingPaybox = urlParams.get("paybox");
  const ab = urlParams.get("ab");
  const vr = urlParams.get("vr");

  return {
    utm_source,
    utm_medium,
    utm_campaign,
    codePromo,
    forcingPaybox,
    forcingFront,
    startDate,
    ab,
    vr,
  };
};
